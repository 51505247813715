

import React, { Component, createRef } from 'react';
import { Empty, Table } from 'antd';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import partial from 'lodash/partial';
import sortBy from 'lodash/sortBy';
import { PropTypes as MobXPropTypes, inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import LabelValueTable from 'src/components/common/label-value-table';
import AppIcon from '../app-icon';
import {
  CELL_OVERFLOW_STYLES,
  DEFAULT_CELL_OVERFLOW_STYLE,
} from './cell-overflow-styles';
import CELLS from './cell-renderers';
import { SELECTION_WIDTH } from './cell-renderers/selection-cell';
import { DefaultTd, DefaultTh, ResizableTh } from './cells';
import {
  bodyCellClsPrefix,
  bodyRowClsPrefix,
  appTableClsPrefix as clsPrefix,
  headerCellClsPrefix,
} from './cls-prefixes';
import {
  DEFAULT_RESPONSIVE_COLUMNS_MODE,
  RESPONSIVE_COLUMNS_MODES,
} from './responsive-columns-modes';
import { DefaultTr } from './rows';
import { sizes, tableHPadding, tableHPaddingXs } from './sizes';
import SORTERS from './sorters';
import SortDownIcon from '!!svgJsx!src/images/icons/triangle-down.svg';
import SortNoneIcon from '!!svgJsx!src/images/icons/triangle-up-and-down.svg';
import SortUpIcon from '!!svgJsx!src/images/icons/triangle-up.svg';

const DEFAULT_PAGE_SIZE = 40;

const LabelValueRow = LabelValueTable.Row;

/*
  Wrapper around Antd's Table Component: https://ant.design/components/table/

  All Antd's Table features should work, except for the ones noted below.

  Features that are known not to work:
    * setting sorter on a column will be ignored if our own "sort" config is set
    * table pagination and filters usage will present issues in combination with custom "sort"

    * column.ellipsis (replaced by overflowStyle and column.overflowStyle prop: one of
      'wrap', 'no-wrap', 'ellipsis').

    * Builtin editable cells wont work for responsiveColumns expandable row values -
      can use custom cell renderer instead

    * We are overriding size prop for our own, so Antd Table docs will not
      provide good guidance on our sizes
*/

// eslint-disable-next-line
const { Selection } = CELLS;

const _DEFAULT_COLUMN_MIN_WIDTH = 50;

const _getCellRenderer = (cellRendererKey) => {
  const renderer = CELLS[cellRendererKey];
  if (!renderer) {
    console.error(
      `Unknown cell renderer: '${cellRendererKey}'. Available cells are: ${Object.keys(
        CELLS
      )
        .map((r) => `'${r}'`)
        .join(', ')}`
    );
    return undefined;
  }

  return renderer;
};

const IS_GHOST_KEY = '__isGhostObject';
const IS_DROPPABLE_PLACEHOLDER_KEY = '__isDroppablePlaceholderObject';

@inject('ui')
@observer
class AppTable extends Component {
  state = {
    columns: {},
    orderedSelectedRows: [],
    lastSelectedRow: null,
    sortValue: null,
  };

  tableWrapperRef = createRef();

  static propTypes = {
    ui: PropTypes.object.isRequired,
    columns: MobXPropTypes.arrayOrObservableArray.isRequired,
    size: PropTypes.oneOf(Object.values(sizes)),
    data: MobXPropTypes.arrayOrObservableArray,
    responsiveColumns: PropTypes.oneOf([
      true,
      false,
      ...Object.values(RESPONSIVE_COLUMNS_MODES),
    ]),
    columnMinWidth: PropTypes.number,
    resizableColumns: PropTypes.bool,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singlePage: PropTypes.bool,
    hideSinglePagePagination: PropTypes.bool,
    overflowStyle: PropTypes.oneOf([...Object.values(CELL_OVERFLOW_STYLES)]),
    draggableRows: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    droppableRows: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    observeOn: PropTypes.array,
    ghostRow: PropTypes.any,
    components: PropTypes.object,
    pagination: PropTypes.object,
    onRow: PropTypes.func,
    rowSelection: PropTypes.any,
    className: PropTypes.string,
    expandedRowRender: PropTypes.any,
    droppablePlaceholder: PropTypes.any,
    sort: PropTypes.oneOfType([
      PropTypes.bool, // true enables sorting for all columns with no default sort
      PropTypes.shape({
        columns: PropTypes.array, // if not set, all columns will be sortable
        default: PropTypes.array,
      }),
    ]),
    onChange: PropTypes.func,
    locale: PropTypes.object,
  };

  static defaultProps = {
    size: sizes.medium,
    columnMinWidth: _DEFAULT_COLUMN_MIN_WIDTH,
    rowKey: 'id',
    overflowStyle: DEFAULT_CELL_OVERFLOW_STYLE,
    hideSinglePagePagination: true,
  };
  static RESPONSIVE_COLUMNS_MODES = RESPONSIVE_COLUMNS_MODES;
  static CELL_OVERFLOW_STYLES = CELL_OVERFLOW_STYLES;

  static getDerivedStateFromProps(props, state) {
    if (state.sortValue !== null) {
      return null;
    }

    return {
      sortValue:
        props.sort && props.sort.default
          ? props.sort.default.reduce(
              (sortValue, [fieldKey, dir], idx) => ({
                ...sortValue,
                [fieldKey]: {
                  order: dir,
                  priority: idx + 1,
                },
              }),
              {}
            )
          : {},
    };
  }

  setColumnState = (column, newColumnState, options) => {
    const { override = false, callback } = options || {};
    const columnKey = get(column, 'key') || column;
    if (columnKey) {
      this.setState(
        (prevState) => ({
          ...prevState,
          columns: {
            ...prevState.columns,
            [columnKey]: {
              ...(!override ? prevState.columns[columnKey] || {} : {}),
              ...(newColumnState || {}),
            },
          },
        }),
        callback
      );
    }
  };

  get dataRows() {
    return this.props.data.filter((row) => this.isDataRow(row));
  }

  selectWithShiftKey(rowKey) {
    const { rowSelection } = this.props;
    const { selectedRowKeys } = rowSelection;

    // Removing ghost columns
    const allRows = this.dataRows;
    const allIds = allRows.map((row) => this.getRowKey(row.data));

    const from = this.state.lastSelectedRow;
    const to = rowKey;

    let fromIndex = allIds.indexOf(from);
    let toIndex = allIds.indexOf(to);

    if (fromIndex > toIndex) {
      const originalFromIndex = fromIndex;
      fromIndex = toIndex;
      toIndex = originalFromIndex;
    }
    // Selecting all checkbox between from and to
    // and don't unselect previous selected
    return Array.from(
      new Set(selectedRowKeys.concat(allIds.slice(fromIndex, toIndex + 1)))
    );
  }

  get validColumns() {
    const { rowSelection, data: d } = this.props;

    const validColumns = (this.props.columns || []).filter((c) => !!c);

    if (!rowSelection) {
      return validColumns;
    }

    const {
      selectedRowKeys = [],
      onChange,
      getCheckboxProps = () => {},
    } = rowSelection;

    const getCheckboxPropsWithGhostRowLogic = (record) => ({
      ...(getCheckboxProps(record) || {}),
      disabled:
        !!record[IS_GHOST_KEY] ||
        !!record[IS_DROPPABLE_PLACEHOLDER_KEY] ||
        !!(getCheckboxProps(record) || {}).disabled,
      hidden:
        !!record[IS_GHOST_KEY] ||
        !!record[IS_DROPPABLE_PLACEHOLDER_KEY] ||
        !!(getCheckboxProps(record) || {}).hidden,
    });

    const data = d || [];

    return [
      {
        key: '__selection',
        render: (v, row) => {
          const rowKey = this.getRowKey(row);
          return (
            <Selection
              checked={selectedRowKeys.includes(rowKey)}
              onChange={(checked, isShiftPressed) => {
                let newSelectedRowKeys = checked
                  ? Array.from(new Set(selectedRowKeys.concat(rowKey)))
                  : selectedRowKeys.filter((k) => k !== rowKey);

                // Here we save which is the last checked checkbox
                // eslint-disable
                let orderedSelectedRows = [...this.state.orderedSelectedRows];
                if (checked) {
                  if (isShiftPressed) {
                    newSelectedRowKeys = this.selectWithShiftKey(rowKey);
                  }
                  orderedSelectedRows.push(rowKey);
                } else {
                  orderedSelectedRows = orderedSelectedRows.filter(
                    (key) => key !== rowKey
                  );
                }
                this.setState({
                  orderedSelectedRows,
                  lastSelectedRow:
                    orderedSelectedRows[orderedSelectedRows.length - 1],
                });

                if (onChange) {
                  onChange(
                    newSelectedRowKeys,
                    data.filter((r) =>
                      newSelectedRowKeys.includes(this.getRowKey(r))
                    )
                  );
                }
              }}
              {...getCheckboxPropsWithGhostRowLogic(row)}
            />
          );
        },
        onHeaderCell: () => {
          const actualData = this.dataRows;
          const allKeys = actualData.map((r) => this.getRowKey(r));
          const relevantSelectedRowKeys = selectedRowKeys.filter((rk) =>
            allKeys.includes(rk)
          );
          return {
            selection: true,
            selectionHeaderProps: {
              someSelected: relevantSelectedRowKeys.length > 0,
              allSelected:
                actualData.length &&
                relevantSelectedRowKeys.length === actualData.length,
              selectAll: onChange
                ? () => onChange(allKeys, actualData)
                : undefined,
              deselectAll: onChange ? () => onChange([], []) : undefined,
            },
          };
        },
        glideResponsive: {
          doNotHide: true,
        },
        fullHeight: true,
        fullWidth: true,
        width: SELECTION_WIDTH,
      },
      ...validColumns,
    ];
  }

  getColumnWidths = () => {
    const { columnMinWidth, ui } = this.props;
    const { columns: columnsState, tableWidth } = this.state;

    const columnWidths = {};

    this.validColumns.forEach((column) => {
      const columnState = columnsState[column.key] || {};
      const minWidth = column.minWidth || column.width || columnMinWidth;
      columnWidths[column.key] = {
        key: column.key,
        width: Math.max(
          columnState.width || column.width || minWidth,
          minWidth
        ),
        fixed: !!column.width,
      };
    });

    const getTotalWidth = (cols) =>
      cols.reduce((totalWidth, { width }) => totalWidth + width, 0.0);
    const wiggleRoom =
      tableWidth -
      2 * (ui.isMobileSize ? tableHPaddingXs : tableHPadding) -
      getTotalWidth(Object.values(columnWidths));
    // See if there is available width to be used by non-fixed width columns.
    // If there is assign it proportionally by current width
    if (wiggleRoom > 0) {
      const nonFixedColumns = Object.values(columnWidths).filter(
        ({ fixed }) => !fixed
      );
      const totalNonFixedColumnsWidth = getTotalWidth(nonFixedColumns);

      nonFixedColumns.forEach((column) => {
        columnWidths[column.key].width =
          column.width +
          wiggleRoom * (column.width / totalNonFixedColumnsWidth);
      });
    }

    return columnWidths;
  };

  setSort = (col, order, additive) => {
    this.setState(
      (prevState) => {
        const { sortValue: oldSortValue } = prevState;
        let newSortValue = {};
        if (!additive && order) {
          newSortValue = {
            [col.key]: {
              order,
              priority: 1,
            },
          };
        } else if (additive) {
          const existingValue = oldSortValue[col.key];

          // New sort value if same as before, changing the order for the currently edited col. If order is no set, then current col should be excluded and sort priorities recomputed accordingly.
          newSortValue = sortBy(
            Object.entries(oldSortValue || {}).filter(
              ([colKey]) => Boolean(order) || colKey !== col.key
            ),
            ([_k, v]) => v.priority
          ).reduce(
            (sortValue, [k, v], idx) => ({
              ...sortValue,
              [k]: {
                ...v,
                order: k === col.key ? order : v.order,
                priority: idx + 1,
              },
            }),
            {}
          );

          // If value wasn't already being sorted, and an order value is provided, add with
          // lowest priority
          if (!existingValue && order) {
            newSortValue[col.key] = {
              order,
              priority: Object.keys(oldSortValue).length + 1,
            };
          }
        }

        return {
          ...prevState,
          sortValue: newSortValue,
        };
      },
      () => this.onChange(undefined, undefined)
    ); // TODO this will break regular Atnd's table pagination and filters usage
  };

  getColumnsSortableProps = (col) => {
    const { sort } = this.props;
    const { sortValue } = this.state;
    const sortable =
      sort === true ||
      (sort && (!sort.columns || sort.columns.includes(col.key)));

    const value =
      sortable && sortValue && sortValue[col.key]
        ? sortValue[col.key]
        : undefined;
    const VALUE_TRANSITIONS = {
      [undefined]: 'asc',
      asc: 'desc',
      desc: undefined,
    };

    return {
      sortable,
      sortValue: value,
      setSort: partial(
        this.setSort,
        col,
        VALUE_TRANSITIONS[value ? value.order : undefined]
      ),
    };
  };

  getColumns = () => {
    const { columnMinWidth, resizableColumns, size, sort } = this.props;
    const { columns: columnsState } = this.state;

    const columnWidths = this.getColumnWidths();

    return this.validColumns
      .filter((col) => !!col && isObject(col))
      .map((column, colIndex) => {
        const columnState = columnsState[column.key] || {};
        const minWidth = column.minWidth || column.width || columnMinWidth;
        const width =
          (columnWidths[column.key] || {}).width ||
          Math.max(columnState.width || column.width || minWidth, minWidth);
        let sorter = !sort ? column.sorter : undefined;
        if (sorter && typeof sorter === 'string') {
          if (SORTERS[sorter]) {
            sorter = (rec1, rec2, sortOrder) =>
              SORTERS[column.sorter](
                get(rec1, column.dataIndex),
                get(rec2, column.dataIndex),
                sortOrder
              );
          } else {
            sorter = undefined;
          }
        }

        const overflowStyle = {
          overflowStyle: this.getColumnsOverflowStyle(column),
        };

        let render;
        if (isFunction(column.render)) {
          render = column.render;
        } else if (isString(column.render)) {
          const Renderer = _getCellRenderer(column.render);
          let getRendererProps = column.renderProps || ((props) => props);
          if (!isFunction(getRendererProps) && isObject(getRendererProps)) {
            // If renderProps was provided for column and is an object, set getRendererProps
            // as a function that returns a copy of that object to pass down the props to
            // the Renderer Component as they are
            getRendererProps = () => ({
              ...column.renderProps,
            });
          }
          render = (value, record, rowIndex) => (
            <Renderer
              {...getRendererProps({
                value,
                record,
                rowIndex,
              })}
            />
          );
        }
        const { verticalAlign, className } = column;

        if (column.sorter && !column.isSortedTitleCustom) {
          column.isSortedTitleCustom = true;
          const { title: originalTitle } = column;
          column.title = (titleProps) => {
            let titleNode;
            if (typeof originalTitle === 'function') {
              titleNode = originalTitle(titleProps);
            }
            if (typeof originalTitle === 'string') {
              titleNode = originalTitle;
            }
            const { sortColumns } = titleProps;
            const sortedColumn = sortColumns?.find(
              ({ column: col }) => col.key === column.key
            );
            return (
              <div className={`${headerCellClsPrefix}__custom-sorted-title`}>
                <span
                  className={`${headerCellClsPrefix}__custom-sorted-title-label`}
                >
                  {titleNode}
                </span>
                <AppIcon
                  className={classNames(
                    `${headerCellClsPrefix}__custom-sorted-title-icon`,
                    {
                      [`${headerCellClsPrefix}__custom-sorted-title-icon-asc`]:
                        sortedColumn.order === 'ascend',
                      [`${headerCellClsPrefix}__custom-sorted-title-icon-desc`]:
                        sortedColumn.order === 'descend',
                      [`${headerCellClsPrefix}__custom-sorted-title-icon-none`]:
                        !sortedColumn.order,
                    }
                  )}
                  type="svg"
                  size={16}
                  svg={
                    sortedColumn.order
                      ? sortedColumn.order === 'ascend'
                        ? SortUpIcon
                        : SortDownIcon
                      : SortNoneIcon
                  }
                />
              </div>
            );
          };
        }

        return {
          ...column,
          ...columnState,
          minWidth,
          width,
          sorter,
          render,
          className: classNames(className, {
            [`${bodyCellClsPrefix}--valign-${verticalAlign}`]: verticalAlign,
          }),
          onHeaderCell: (col) => ({
            ...(column.onHeaderCell ? column.onHeaderCell(col) || {} : {}),
            ...(resizableColumns
              ? {
                  width,
                  onResize: this.handleColumnResize(col),
                }
              : {}),
            ...overflowStyle,
            ...this.getColumnsSortableProps(col),
          }),
          onCell: (record, index) => ({
            ...(column.onCell ? column.onCell(record, index) || {} : {}),
            ...overflowStyle,
            colIndex,
            isDraggableRow: this.isRowDraggable(record, index),
            isFullHeight: !!column.fullHeight,
            isFullWidth: !!column.fullWidth,
            noWrapper: !!column.noWrapper,
            width,
            size,
          }),
        };
      });
  };

  setColumnWidth = (column, width) => {
    this.setColumnState(column, {
      width: Math.max(column.minWidth || 0, width),
    });
  };

  handleColumnResize =
    (column) =>
    (event, { size: { width } }) =>
      this.setColumnWidth(column, width);

  getColumnsResponsiveMode = (column) => {
    const { responsiveColumns: tableResponsiveColumns } = this.props;
    if (!tableResponsiveColumns) {
      return null;
    }
    // replace responsive, because it's retained by antd v4 table
    const mode =
      (isObject(column.glideResponsive)
        ? column.glideResponsive.mode
        : column.glideResponsive) || tableResponsiveColumns;
    if (mode === true) {
      return DEFAULT_RESPONSIVE_COLUMNS_MODE;
    }
    if (RESPONSIVE_COLUMNS_MODES[mode]) {
      return mode;
    }
    return DEFAULT_RESPONSIVE_COLUMNS_MODE;
  };

  getResponsiveColumnsDefinition = () => {
    if (!this.props.responsiveColumns) {
      return this.getColumns();
    }
    return this.getColumns().sort((col1, col2) => {
      const col1RespPriority = (col1.glideResponsive || {}).priority;
      const col2RespPriority = (col2.glideResponsive || {}).priority;
      if (col1RespPriority !== undefined && col2RespPriority === undefined) {
        return -1;
      }
      if (col1RespPriority === undefined && col2RespPriority !== undefined) {
        return 1;
      }
      if (col1RespPriority !== undefined && col2RespPriority !== undefined) {
        return col1RespPriority > col2RespPriority ? 1 : -1;
      }
      return 0;
    });
  };

  getDOMTableWidth = () => {
    const tableWrapperRef = this.tableWrapperRef.current;
    const antDTableWrapper = tableWrapperRef
      ? tableWrapperRef.querySelector('.ant-table-wrapper')
      : null;
    return antDTableWrapper ? antDTableWrapper.offsetWidth : null;
  };

  screenSizeChanged = () =>
    !this._unmounted &&
    this.setState({
      tableWidth: this.getDOMTableWidth(),
    });
  screenSizeChangedDebounced = debounce(this.screenSizeChanged, 250);

  getAlwaysVisibleColumnKeys = () => {
    const { responsiveColumns } = this.props;
    const { tableWidth: domTableWidth } = this.state;
    let totalWidth = 0;
    return this.getResponsiveColumnsDefinition()
      .filter((c, idx) => {
        const isFirstColumn = !idx;
        totalWidth += c.minWidth;
        return (
          !responsiveColumns ||
          isFirstColumn ||
          !!(c.glideResponsive || {}).doNotHide ||
          totalWidth <= domTableWidth
        );
      })
      .map((c) => c.key);
  };

  getColumnsByVisibility = () => {
    const columns = this.getColumns();
    const alwaysVisibleColumnKeys = this.getAlwaysVisibleColumnKeys();
    return {
      alwaysVisible: columns.filter((c) =>
        alwaysVisibleColumnKeys.includes(c.key)
      ),
      expandable: columns.filter(
        (c) =>
          !alwaysVisibleColumnKeys.includes(c.key) &&
          this.getColumnsResponsiveMode(c) ===
            RESPONSIVE_COLUMNS_MODES.EXPAND_ROW
      ),
      hidden: columns.filter(
        (c) =>
          !alwaysVisibleColumnKeys.includes(c.key) &&
          this.getColumnsResponsiveMode(c) === RESPONSIVE_COLUMNS_MODES.HIDE
      ),
    };
  };

  getAlwaysVisibleColumns = () => this.getColumnsByVisibility().alwaysVisible;
  getExpandableColumns = () => this.getColumnsByVisibility().expandable;
  getHiddenColumns = () => this.getColumnsByVisibility().hidden;

  getExpandedRowRender = () => {
    const expandableColumns = this.getExpandableColumns();

    return expandableColumns.length
      ? (record) => (
          <div className={`${clsPrefix}__expandable-data`}>
            {expandableColumns.map((c) => {
              const render =
                (c.glideResponsive || {}).render || c.render || ((val) => val);
              return (
                <LabelValueRow
                  key={c.key}
                  className={`${clsPrefix}__expandable-row`}
                  label={<strong>{c.title}</strong>}
                >
                  <span>{render(get(record, c.dataIndex), record)}</span>
                </LabelValueRow>
              );
            })}
          </div>
        )
      : undefined;
  };

  componentDidMount() {
    this._unmounted = false;
    window.addEventListener('resize', this.screenSizeChangedDebounced);
    this.screenSizeChanged();
  }

  componentWillUnmount() {
    this._unmounted = true;
    window.removeEventListener('resize', this.screenSizeChangedDebounced);
  }

  getColumnsOverflowStyle = (column) =>
    column.overflowStyle || this.props.overflowStyle;

  get components() {
    // TODO (Marlo) WARNING: if components or resizableColumns need to change dynamically,
    //  then this method should be edited, maybe add a dynamicComponents prop that, if
    //  set to true, ignores this or runs a deep comparison in each update to see if it
    //  should invalidate store this,_components value.
    if (this._components) {
      return this._components;
    }

    const { components = {}, resizableColumns } = this.props;
    const HeaderCell =
      (components.header || {}).cell || resizableColumns
        ? ResizableTh
        : DefaultTh;
    const BodyCell = (components.body || {}).cell || DefaultTd;

    this._components = {
      ...components,
      header: {
        ...(components.header || {}),
        cell: HeaderCell,
      },
      body: {
        ...(components.body || {}),
        row: (components.body || {}).row || DefaultTr,
        cell: BodyCell,
      },
    };

    return this._components;
  }

  get pagination() {
    const { pagination, singlePage, hideSinglePagePagination, data } =
      this.props;

    // pagination.total represents the total number of items available
    // even if not all of those items have been fetched/returned as 'data' in the request.
    // So data.length can be < pagination.total.
    // And we would only hide pagination options
    // when pagination.total indicates we have fewer total items than fit in one
    // page based on our items per page (pageSize).
    if (
      singlePage ||
      (hideSinglePagePagination &&
        (pagination?.total ?? (data || []).length) <=
          parseInt(get(pagination, 'pageSize') || DEFAULT_PAGE_SIZE, 0))
    ) {
      return false;
    }

    if (
      pagination === true ||
      pagination === undefined ||
      pagination === null
    ) {
      return {
        pageSize: DEFAULT_PAGE_SIZE,
      };
    }

    return pagination;
  }

  getRowKey = (record) => {
    const { rowKey = 'id' } = this.props;
    return isFunction(rowKey) ? rowKey(record) : get(record, rowKey);
  };

  isGhostRow = (row) => !!row[IS_GHOST_KEY];
  isDroppablePlaceholderRow = (row) => !!row[IS_DROPPABLE_PLACEHOLDER_KEY];
  isDataRow = (row) =>
    !this.isGhostRow(row) && !this.isDroppablePlaceholderRow(row);
  isDraggableOrDroppable = (prop, row, index) =>
    this.isDataRow(row) && !!prop && (prop === true || prop(row, index));
  isRowDraggable = (row, index) =>
    this.isDraggableOrDroppable(this.props.draggableRows, row, index);
  isRowDroppable = (row, index) =>
    this.isDraggableOrDroppable(this.props.droppableRows, row, index);

  get onRow() {
    const { data, onRow, draggableRows, droppablePlaceholder } = this.props;

    const addClickableClassOnRow =
      (originalOnRow) =>
      (...args) => {
        const onRowObj = originalOnRow(...args);
        if (!!onRowObj && !!onRowObj.onClick) {
          return {
            ...onRowObj,
            className: [
              onRowObj.className || '',
              `${bodyRowClsPrefix}--clickable`,
            ]
              .join(' ')
              .trim(),
          };
        }
        return onRowObj;
      };

    return (record, index) => {
      const isGhostRow = !!record[IS_GHOST_KEY];
      const isDroppablePlaceholderRow = !!record[IS_DROPPABLE_PLACEHOLDER_KEY];
      const isDraggable = this.isRowDraggable(record, index);
      const isDroppable = this.isRowDroppable(record, index);

      const rowDraggableIndex = isDraggable
        ? index -
          (data || [])
            .slice(0, index)
            .filter((r) => draggableRows !== true && !draggableRows(r)).length
        : undefined;

      return {
        rk: this.getRowKey(record),
        rowIndex: index,
        rowDraggableIndex,
        isGhostRow,
        droppablePlaceholder: isDroppablePlaceholderRow
          ? droppablePlaceholder
          : undefined,
        isDraggable,
        isDroppable,
        ...(onRow ? addClickableClassOnRow(onRow)(record, index) || {} : {}),
      };
    };
  }

  applyGhostRow() {
    const {
      data: [...d],
      rowKey: originalRowKey,
      ghostRow,
      droppablePlaceholder,
    } = this.props;
    const columns = this.getAlwaysVisibleColumns();
    const data = d || []; // for some reason copying data to avoid modifying object is not working, so stateless functional approach is not working

    const ghostObject = {
      id: -99999,
      [IS_GHOST_KEY]: true,
    };
    const dataHasGhostObject = data.some((obj) => !!obj[IS_GHOST_KEY]);

    if (
      !!ghostRow &&
      (!ghostRow.show ||
        ghostRow.show({
          data,
          columns,
        }))
    ) {
      const ghostContent = ghostRow.render ? (
        ghostRow.render(data, columns)
      ) : (
        <span>Ghost</span>
      );
      if (!dataHasGhostObject) {
        data.push(ghostObject);
      }
      const ghostRowIndex = data.length - 1;

      const firstColWrappedRender = columns[0].render || ((value) => value);
      columns[0].render = (value, record, rowIndex) => {
        if (rowIndex !== ghostRowIndex) {
          return firstColWrappedRender(value, record, rowIndex);
        }
        return {
          children: ghostContent,
          props: {
            colSpan: columns.length,
          },
        };
      };

      columns.slice(1).forEach((col) => {
        const wrappedRender = col.render || ((value) => value);
        col.render = (value, record, rowIndex) => {
          if (rowIndex !== ghostRowIndex) {
            return wrappedRender(value, record, rowIndex);
          }
          return {
            props: {
              colSpan: 0,
            },
          };
        };
      });
    } else if (dataHasGhostObject) {
      // If not ghost row but ghost object exists in `data`, remove
      data.splice(data.map((obj) => !!obj[IS_GHOST_KEY]).indexOf(true), 1);
    }

    const droppablePlaceholderObject = {
      id: -999999,
      [IS_DROPPABLE_PLACEHOLDER_KEY]: true,
    };
    const dataHasDroppablePlaceholderObject = data.some(
      (obj) => !!obj[IS_DROPPABLE_PLACEHOLDER_KEY]
    );

    if (droppablePlaceholder) {
      if (!dataHasDroppablePlaceholderObject) {
        data.push(droppablePlaceholderObject);
      }
    } else if (dataHasDroppablePlaceholderObject) {
      // If not droppablePlaceholder is provided but droppablePlaceholder object exists in `data`, remove
      data.splice(
        data.map((obj) => !!obj[IS_DROPPABLE_PLACEHOLDER_KEY]).indexOf(true),
        1
      );
    }

    const rowKey = (record) => {
      if (record[IS_GHOST_KEY]) {
        return ghostObject.id;
      }
      if (record[IS_DROPPABLE_PLACEHOLDER_KEY]) {
        return droppablePlaceholderObject.id;
      }
      if (isFunction(originalRowKey)) {
        return originalRowKey(record);
      }
      if (isString(originalRowKey)) {
        return get(record, originalRowKey);
      }
      return record.id;
    };

    return [columns, data, rowKey];
  }

  getSorterValue = (sorter) => {
    const { sort } = this.props;
    if (!sort) {
      return sorter;
    }

    return sortBy(
      Object.entries(this.state.sortValue || {}),
      ([_k, v]) => v.priority
    ).map(([k, v]) => [k, v.order]);
  };

  onChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(pagination, filters, this.getSorterValue(sorter));
    }
  };

  render() {
    const {
      className,
      expandedRowRender,
      size,
      observeOn,
      locale,
      ...otherTableProps
    } = this.props;

    const [columns, data, rowKey] = this.applyGhostRow();

    (observeOn || []).forEach((oo) => noop(oo));

    return (
      <div
        ref={this.tableWrapperRef}
        className={classNames(clsPrefix, `${clsPrefix}--${size}`, className)}
      >
        <Table
          {...otherTableProps}
          columns={columns}
          components={this.components}
          expandedRowRender={expandedRowRender || this.getExpandedRowRender()}
          rowKey={rowKey}
          dataSource={data}
          pagination={this.pagination}
          onRow={this.onRow}
          rowSelection={null} // Antd Table's default selection is overridden - see get validColumns(...)
          onChange={this.onChange}
          locale={{
            emptyText: <Empty description="Nothing here yet" />,
            ...locale,
          }}
        />
      </div>
    );
  }
}

Object.entries(CELLS).forEach(([k, cellRenderer]) => {
  AppTable[k] = cellRenderer;
});

export default AppTable;
